<template>
  <v-container>
    <v-progress-circular
      v-if="stock_brands_fetching"
      class="mx-auto d-block mt-16"
      indeterminate
      color="primary"
      size="80"
    ></v-progress-circular>
    <div v-else>
      <div
        class="d-flex flex-row pa-3 pb-0 align-baseline justify-space-between"
      >
        <v-text-field
          v-model.trim="search"
          clearable
          hint="Поиск от 3 символов"
          outlined
          persistent-hint
          placeholder="Поиск бренда по наименованию или 1С-коду"
          @click:clear="tryToSearch"
          @keyup.enter="tryToSearch"
        />
        <v-btn
          color="primary"
          style="top: 3px"
          class="ml-4 relative"
          x-large
          @click="tryToSearch"
        >
          <v-icon>mdi-magnify</v-icon>
        </v-btn>
      </div>
      <div class="d-flex flex-row">
        <v-checkbox
          class="mr-4"
          v-model="filterWithoutImages"
          label="Без картинок"
          @change="tryToSearch"
        />
        <v-checkbox
          class="mr-4"
          v-model="filterWithoutDescription"
          label="Без описания"
          @change="tryToSearch"
        />
      </div>
      <v-expansion-panels
        :multiple="searchMode"
        v-model="searchMode ? expandWhenSearch : expansion"
      >
        <v-expansion-panel
          v-for="(brand_category, brand_idx) in tree"
          :key="brand_category.id"
        >
          <v-expansion-panel-header>
            {{ brand_category.name }}
            <v-spacer></v-spacer>
            <div class="d-flex justify-end" v-if="hasUpdatePermissions">
              <v-tooltip left>
                <template #activator="{ on, attrs }">
                  <v-btn
                    class="mr-8"
                    x-small
                    v-on="on"
                    v-bind="attrs"
                    fab
                    @click.stop="openEditor(null, brand_category.id)"
                  >
                    <v-icon color="green">mdi-plus</v-icon>
                  </v-btn>
                </template>
                Добавить новый бренд в "{{ brand_category.name }}"
              </v-tooltip>
            </div>
          </v-expansion-panel-header>
          <v-expansion-panel-content
            v-if="searchMode ? true : brand_idx === expansion"
          >
            <div class="d-flex flex-wrap justify-around">
              <v-lazy
                class="col-sm-4 col-lg-3"
                v-for="brand in brand_category.children"
                :key="brand.id"
              >
                <v-sheet>
                  <v-card rounded @click="openEditor(brand)">
                    <v-card-text
                      class="d-flex justify-center"
                      style="height: 120px"
                    >
                      <v-img
                        :src="brand.image_url"
                        contain
                        v-if="brand.image_url"
                      />
                      <v-img :src="noPhotoImg" contain v-else></v-img>
                    </v-card-text>
                    <v-card-subtitle class="d-flex">
                      {{ brand.name }}
                      <v-spacer/>
                      <v-tooltip bottom>
                        <template #activator="{ on, attrs }">
                          <v-chip small v-on="on" v-bind="attrs">
                            <v-avatar>
                              <v-icon>mdi-sort</v-icon>
                            </v-avatar>
                            &nbsp;{{ brand.priority }}
                          </v-chip>
                        </template>
                        Приоритет
                      </v-tooltip>
                    </v-card-subtitle>
                  </v-card>
                </v-sheet>
              </v-lazy>
            </div>
          </v-expansion-panel-content>
        </v-expansion-panel>
      </v-expansion-panels>
      <h2
        v-if="filteredBrands instanceof Array && filteredBrands.length === 0"
        class="h2 d-flex align-baseline justify-center pt-16"
        style="color: lightgray"
      >
        Совпадений не найдено
      </h2>
    </div>

    <v-dialog
      :persistent="editorPushing"
      width="800px"
      v-model="editorShow"
      scrollable
      @close="closeEditor"
      @click:outside="closeEditor"
    >
      <v-card :loading="editorPushing">
        <v-card-title class="d-flex justify-space-between">
          <template v-if="editorOriginal">
            <span>Редактирование бренда "{{ editorOriginal.name }}"</span>
            <v-tooltip v-if="hasUpdatePermissions" bottom>
              <template v-slot:activator="{ on, attrs }">
                <span
                  v-bind="attrs"
                  v-on="on"
                  @click="toggleBrandEnabled"
                  class="brand-delete"
                >
                  <template v-if="editorEnabled">
                    Пометить на удаление:
                  </template>
                  <template v-else>
                    Убрать с удаления
                  </template>
                  <v-icon v-if="editorEnabled" color="red" size="x-large">
                    mdi-delete
                  </v-icon>
                  <v-icon v-else color="green">mdi-eye</v-icon>
                </span>
              </template>
              <span v-if="editorEnabled">
                Номенклатура помечается на удаление и будет удалена средствами 1с, после чего исчезнет в агрегаторе товаров
              </span>
              <span v-else>
                Убрать пометку на удаление
              </span>
            </v-tooltip>
          </template>
          <span v-else>Создание бренда</span>
        </v-card-title>
        <v-card-text>
          <div
            v-if="editorOriginal"
            style="color: darkgoldenrod; font-weight: 500; font-size: 1.25rem;"
            class="py-6 d-flex flex-row"
          >
            1С:&nbsp;
            <span v-if="editorOriginal._1c_id">
              {{ editorOriginal._1c_id }}
            </span>
            <span v-else style="color: gray">[идентификатор не задан]</span>

            <v-spacer/>

            <v-tooltip bottom v-if="editorId">
              <template #activator="{ on, attrs }">
                <span style="color: gray" v-on="on" v-bind="attrs">
                  #{{ editorId }}
                </span>
              </template>
              <span>Идентификатор в агрегаторе</span>
            </v-tooltip>
          </div>
          <v-select
            outlined
            label="Категория"
            v-model="editorCategory"
            :items="getStockBrandsTree"
            item-text="name"
            class="mt-1"
            item-value="id"
            :disabled="editorPushing"
            :readonly="!hasUpdatePermissions"
          ></v-select>
          <v-text-field
            :disabled="editorPushing"
            outlined
            label="Наименование на русском"
            v-model="editorName"
            :rules="[value => !!value || 'Required.']"
            :readonly="!hasUpdatePermissions"
          />
          <v-text-field
            :disabled="editorPushing"
            outlined
            label="Наименование на английском"
            v-model="editorEnglishName"
            :rules="[value => !!value || 'Required.']"
            :readonly="!hasUpdatePermissions"
          />
          <v-text-field
            :disabled="editorPushing"
            outlined
            label="Адрес сайта бренда или производителя"
            v-model="editorLink"
            :readonly="!hasUpdatePermissions"
          />
          <v-slider
            class="mt-2"
            v-model="editorPriority"
            label="Приоритет"
            min="0"
            max="100"
            step="5"
            ticks
            tick-size="5"
            thumb-label="always"
            :disabled="editorPushing"
            :readonly="!hasUpdatePermissions"
          />
          <v-switch
            class="mt-2"
            v-model="editorIsShowOnMainPage"
            label="Показывать на главной странице"
            min="0"
            max="100"
            step="5"
            ticks
            tick-size="5"
            thumb-label="always"
            :disabled="editorPushing"
            :readonly="!hasUpdatePermissions"
          ></v-switch>
          <v-img
            contain
            height="150px"
            :src="preview"
            v-if="editorImageData"
          ></v-img>
          <div
            v-else-if="editorImageUrl"
            class="d-flex align-center flex-column"
          >
            <v-img contain height="150px" :src="editorImageUrl"/>
            <v-tooltip v-if="hasUpdatePermissions">
              <template #activator="{ on, attrs }">
                <v-btn
                  fab
                  v-on="on"
                  v-bind="attrs"
                  @click="editorImageUrl = null"
                  class="mt-n11"
                  small
                >
                  <v-icon color="red">mdi-delete</v-icon>
                </v-btn>
              </template>
            </v-tooltip>
          </div>

          <v-img contain height="150px" :src="noPhotoImg" v-else/>
          <fieldset
            style="border-style: dashed"
            class="rounded pa-2 mx-1 mt-2"
            v-if="editorImageModerate"
          >
            <legend class="px-2 ml-4">Изображение на модерации</legend>
            <v-row>
              <v-col>
                <v-img
                  @click="openImageInNewTab(editorImageModerate.url)"
                  max-height="150px"
                  contain
                  aspect-ratio="1"
                  :src="editorImageModerate.url"
                />
              </v-col>
            </v-row>
            <ModerateCandidateDialog
              :type="catalogTypes.brand"
              :id="editorImageModerate.id"
              v-if="hasModeratePermission"
              direction="row"
              @fetching="editorPushing = $event"
              @done="onModerationComplete"
            />
          </fieldset>
          <v-file-input
            v-if="hasUploadPermission || hasUpdatePermissions"
            :disabled="editorPushing || !!editorImageModerate"
            clearable
            v-model="editorImageData"
            placeholder="Выбрать новое изображение"
          />
          <v-textarea
            v-model="editorDescription"
            outlined
            label="Описание"
            placeholder="Текстовое описание бренда"
            auto-grow
          />
        </v-card-text>
        <v-card-actions class="justify-end">
          <span v-if="hasUpdatePermissions">
            <v-btn
              :disabled="editorPushing"
              outlined
              @click="closeEditor"
              color="secondary"
            >
              Отмена
            </v-btn>
            <v-btn
              class="ml-2"
              :disabled="editorPushing || !changed"
              outlined
              color="primary"
              @click=";(editorOriginal ? save : create)()"
            >
              <span v-if="editorOriginal">Сохранить</span>
              <span v-else>Создать</span>
            </v-btn>
          </span>
          <span v-else-if="hasUploadPermission">
            <v-btn
              :disabled="editorPushing"
              outlined
              @click="closeEditor"
              color="secondary"
            >
              Отмена
            </v-btn>
            <v-btn
              class="ml-2"
              :disabled="editorPushing || !changed || !!editorImageModerate"
              outlined
              color="primary"
              @click="pushImageToModeration"
            >
              Отправить изображение на модерацию
            </v-btn>
          </span>
          <span v-else>
            <v-btn @click="closeEditor" color="secondary" outlined>
              Закрыть
            </v-btn>
          </span>
        </v-card-actions>
      </v-card>
    </v-dialog>
  </v-container>
</template>

<script>
import {mapActions, mapGetters, mapState} from 'vuex'
import noPhotoImg from '@/assets/images/nophoto.png'
import brokenPhotoImg from '@/assets/images/brokenphoto.png'
import * as routes from '@/router/routes'
import permissions from '@/common/permissions'
import imagesApi from '@/services/imagesApi'
import * as catalogTypes from '@/common/catalogTypes'
import ModerateCandidateDialog from '@/views/images/dialogs/ModerateCandidateDialog.vue'

export default {
  name: 'MainView',
  components: {ModerateCandidateDialog},

  mounted() {
    if (!this.hasReadPermission) {
      this.$router.push('/')
    } else {
      new Promise(resolve => {
        if (
          !this.stock_brands_fetching &&
          this.getStockBrandsTree.length === 0
        ) {
          this.actionFetchStockBrands()
            .then(this.tryToSearch)
            .then(resolve)
        } else {
          resolve()
        }
      }).finally(() => {
        if (this.$route.params.id && /^\d+$/.test(this.$route.params.id)) {
          let brand = this.getStockBrandById(parseInt(this.$route.params.id))

          if (brand) {
            this.openEditor(brand)
          } else {
            this.actionAddErrorMessage('Бренд не найден')
            this.$router.push({name: routes.brandsIndex})
          }
        }
      })
    }
  },

  data: () => ({
    search: '',
    expansion: null,

    editorShow: false,
    editorPushing: false,
    editorOriginal: null,

    editorImageModerate: null,
    editorImageUrl: null,
    editorImageData: null,

    editorDescription: null,
    editorCategory: null,
    editorPriority: 0,
    editorIsShowOnMainPage: false,
    editorName: null,
    editorEnglishName: null,
    editorId: null,
    editorEnabled: null,
    editorLink: null,

    noPhotoImg,
    brokenPhotoImg,
    console,

    searchMode: false,
    filteredBrands: null,
    filterWithoutImages: false,
    filterWithoutDescription: false,
    expandWhenSearch: [],

    catalogTypes
  }),

  methods: {
    ...mapActions('stock_brands', [
      'actionClearStockBrands',
      'actionFetchStockBrands',
      'actionUpdateStockBrand',
      'actionCreateStockBrand',
      'actionFetchStockBrand'
    ]),
    ...mapActions('images', []),
    ...mapActions(['actionAddErrorMessage']),

    closeEditor() {
      this.editorShow = false
      this.$router.replace({name: routes.brandsIndex}, () => {
      })
    },

    onModerationComplete() {
      this.editorPushing = true

      this.actionFetchStockBrand(this.editorId).then(brand => {
        this.openEditor(brand)
      })
    },

    openImageInNewTab(link) {
      window.open(link, '_blank')
    },

    fetchModerationInfo(id) {
      this.editorPushing = true

      return imagesApi
        .getModerate(catalogTypes.brand, {
          filterByTarget: id,
          filterUnverified: true
        })
        .then(({results}) => {
          if (results.length === 1) {
            this.editorImageModerate = {
              url: process.env.VUE_APP_MEDIA_HOST + results[0].image,
              id: results[0].id
            }
          } else {
            this.editorImageModerate = null
          }
        })
        .finally(() => {
          this.editorPushing = false
        })
    },

    openEditor(target, brandCategory = null) {
      this.editorCategory = brandCategory ? brandCategory : null
      if (target) {
        this.$router.replace(
          {name: routes.brandsDetail, params: {id: target.id}},
          () => {
          }
        )

        this.editorId = target.id
        this.editorName = target.name
        this.editorEnglishName = target.english_name
        this.editorEnabled = target.enabled
        this.editorLink = target.link
        this.editorCategory = target.category
        this.editorDescription = target.description
        this.editorPriority = target.priority
        this.editorIsShowOnMainPage = target.is_show_main_page
        this.editorImageUrl = target.image_url
        this.editorOriginal = target
      } else {
        this.editorId = null
        this.editorName = null
        this.editorEnglishName = null
        this.editorEnabled = false
        this.editorLink = null;
        this.editorDescription = null
        this.editorPriority = null
        this.editorIsShowOnMainPage = false
        this.editorImageUrl = null
        this.editorOriginal = null
      }

      this.editorImageData = null
      this.editorShow = true

      this.fetchModerationInfo(target.id)
    },

    save() {
      this.editorPushing = true

      let changes = {}

      if (this.editorName !== this.editorOriginal.name) {
        changes.name = this.editorName
      }
      if (this.editorEnglishName !== this.editorOriginal.english_name) {
        changes.english_name = this.editorEnglishName
      }

      if (this.editorEnabled !== this.editorOriginal.enabled) {
        changes.enabled = this.editorEnabled
      }
      if (this.editorLink !== this.editorOriginal.link) {
        changes.link = this.editorLink
      }

      if (this.editorPriority !== this.editorOriginal.priority) {
        changes.priority = this.editorPriority
      }

      if (this.editorIsShowOnMainPage !== this.editorOriginal.is_show_main_page) {
        changes.is_show_main_page = this.editorIsShowOnMainPage
      }

      if (this.editorCategory !== this.editorOriginal.category) {
        changes.category = this.editorCategory
      }

      if (this.editorDescription !== this.editorOriginal.description) {
        changes.description = this.editorDescription
      }

      if (this.editorImageData) {
        changes.image = this.editorImageData
      } else if (
        this.editorImageUrl !== this.editorOriginal.image &&
        !this.editorImageUrl
      ) {
        changes.image = null
      }

      if (!changes) {
        this.editorShow = false
        return
      }

      this.actionUpdateStockBrand({
        id: this.editorId,
        data: changes
      })
        .then(() => {
          this.editorShow = false
          this.tryToSearch()
        })
        .catch(() => {
          this.editorPushing = false
        })
    },

    pushImageToModeration() {
      this.editorPushing = true

      imagesApi
        .uploadImage(catalogTypes.brand, this.editorId, this.editorImageData)
        .then(() => {
          this.fetchModerationInfo(this.editorId).finally(() => {
            this.editorImageData = null
          })
        })
        .catch(() => {
          this.editorPushing = false
        })
    },

    create() {
      this.editorPushing = true

      this.actionCreateStockBrand({
        name: this.editorName,
        english_name: this.editorEnglishName,
        link: this.editorLink,
        category: this.editorCategory,
        description: this.editorDescription,
        priority: this.editorPriority,
        is_show_main_page: this.editorIsShowOnMainPage,
        image: this.editorImageData
      })
        .then(() => {
          this.editorPushing = false
          this.editorShow = false
          this.tryToSearch()
        })
        .catch(() => {
          this.editorPushing = false
        })
    },

    tryToSearch() {
      let filteredBrands = this.stock_brands

      if (!!this.search && this.search.length >= 3) {
        filteredBrands = filteredBrands.filter(brand => {
          return (
            brand.name.toLowerCase().indexOf(this.search.toLowerCase()) !==
            -1 ||
            (brand._1c_id &&
              brand._1c_id.toLowerCase().indexOf(this.search.toLowerCase()) !==
              -1)
          )
        })
      }

      if (this.filterWithoutImages) {
        filteredBrands = filteredBrands.filter(b => !b.image_url)
      }

      if (this.filterWithoutDescription) {
        filteredBrands = filteredBrands.filter(b => !b.description)
      }

      this.searchMode =
        (!!this.search && this.search.length >= 3) ||
        this.filterWithoutImages ||
        this.filterWithoutDescription
      this.filteredBrands = this.getStockBrandsTreeByIdList(
        filteredBrands.map(brand => brand.id)
      )

      this.$nextTick(() => {
        this.expandWhenSearch =
          filteredBrands.length > 20
            ? []
            : this.filteredBrands.map((b, idx) => idx)
      })
    },
    toggleBrandEnabled() {
      this.editorEnabled = !this.editorEnabled
      this.save()
    }
  },

  computed: {
    ...mapGetters('auth', ['getCurrentUserHasPermission']),

    ...mapState('stock_brands', ['stock_brands_fetching', 'stock_brands']),

    ...mapGetters('stock_brands', [
      'getStockBrandsTree',
      'getStockBrandsTreeByIdList',
      'getStockBrandById'
    ]),

    tree() {
      return this.searchMode ? this.filteredBrands : this.getStockBrandsTree
    },

    preview() {
      return this.editorImageData
        ? URL.createObjectURL(this.editorImageData)
        : null
    },

    changed() {
      if (this.editorOriginal) {
        return (
          this.editorName !== this.editorOriginal.name ||
          this.editorEnglishName !== this.editorOriginal.english_name ||
          this.editorCategory !== this.editorOriginal.category ||
          this.editorDescription !== this.editorOriginal.description ||
          this.editorPriority !== this.editorOriginal.priority ||
          this.editorIsShowOnMainPage !== this.editorOriginal.is_show_main_page ||
          this.editorLink !== this.editorOriginal.link ||
          this.editorImageData ||
          this.editorImageUrl !== this.editorOriginal.image_url
        )
      } else {
        return true
      }
    },

    /**
     * Право доступа - Чтение брендов/категорий брендов
     * @returns boolean
     */
    hasReadPermission() {
      return (
        this.getCurrentUserHasPermission(permissions.apiStockBrands) &&
        this.getCurrentUserHasPermission(permissions.apiStockBrandsCategories)
      )
    },

    /**
     * Право доступа - Обновление брендов
     * @returns boolean
     */
    hasUpdatePermissions() {
      return (
        this.hasReadPermission &&
        this.getCurrentUserHasPermission(permissions.apiStockBrandsUpdate)
      )
    },

    /**
     * Право доступа - Модерация изображений брендов (одобрение/отклонение)
     * @returns boolean
     */
    hasModeratePermission() {
      return (
        this.getCurrentUserHasPermission(permissions.appImagesAdmin) ||
        this.getCurrentUserHasPermission(permissions.appImagesModerate) ||
        this.hasUpdatePermissions
      )
    },

    /**
     * Право доступа - Загрузка изображений на модерацию
     * @returns boolean
     */
    hasUploadPermission() {
      return this.getCurrentUserHasPermission(permissions.apiImagesUpload)
    }
  }
}
</script>

<style scoped>
.brand-delete {
  cursor: pointer;
}
</style>
